import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loading = false;
  formData: any = {};

  constructor(private authService: AuthService, private router: Router) { }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    this.loading = true;
    console.log(email, password)
    const result1 = await this.authService.logIn(email, password)
    .then(result => {
          console.log(result);
        if (!result.isOk) {
          this.loading = false;
          console.log('Error')
          notify(result.message, 'error', 2000);

        }
        console.log('Redireccionando a pantalla principal')
        /*setTimeout(() => {
          console.log('Redirigiendo');


        }, 3000);
        */
        console.log('Redirigiendo');
        this.router.navigate(["/home"]);

        //this.router.navigate(['/login-form']);
      });

  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
