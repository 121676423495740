export const navigation = [
  {
    text: 'Tablero',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Soporte',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tickets',
        path: '/ticket'
      },

    ]
  },
  {
    text: 'Pagos',
    icon: 'money',
    items: [
      {
        text: 'Efectuar',
        path: '/pago'
      },
      {
        text: 'Promesa',
        path: '/promesa'
      }
    ]
  }
];
