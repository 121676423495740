<div class="card">
  <div class="card-header border-0 pt-6">
    <h5 class="card-title">Lista Tickets</h5>
    <div class="card-toolbar">
      <div class="d-flex justify-content-end">
        <div class="dx-fieldset">

          <div class="dx-field">
            <h5 class="me-2">Contrato: </h5>
            <div class="dx-field-value">
              <dx-select-box
                displayExpr="nombre"
                width="350"
                valueExpr="idCliente"
                [dataSource]="contratos"
                (onValueChanged)="cambioContrato($event)"
                [(value)]="contratoActual"
              ></dx-select-box>
            </div>
            <div class="me-2"></div>
            <div class="dx-field-value">
              <button
                class="btn btn-primary"
                (click)="modalOpenDefault()"
                rippleEffect
              >
                <i data-feather="plus" class="mr-0 mr-sm-1"></i
                ><span class="d-none d-sm-inline-block">Add New Record</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    <!-- Invoice List -->

    <dx-data-grid
      #grid
      id="gridContainer"
      [repaintChangesOnly]="true"
      [dataSource]="rows"
      [focusedRowEnabled]="true"
      keyExpr="idTicket"
      [hoverStateEnabled]="true"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      [focusedRowEnabled]="true"
    >
      <dxo-search-panel
        [visible]="true"
        [width]="240"
        placeholder="Search..."
      ></dxo-search-panel>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[5, 10, 15, 20]"
      ></dxo-pager>
      <dxo-export
        [enabled]="true"
        fileName="listado de ticket"
        [allowExportSelectedData]="true"
      ></dxo-export>
      <dxo-paging [pageSize]="15" [enabled]="true"></dxo-paging>
      <dxi-column dataField="idTicket" [width]="240" caption="ID"></dxi-column>
      <dxi-column dataField="estado" caption="Nombre"></dxi-column>
      <dxi-column dataField="tipoTicket" caption="Tipo de Ticket"></dxi-column>
      <dxi-column dataField="fApertura" caption="Fecha Apertura"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplate'"></div>
    </dx-data-grid>
    <!--/ End Table -->
    <div *ngIf="loading" class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="dx-container" id="popudEditar">
  <dx-popup
    class="popup"
    [width]="800"
    [height]="640"
    [showTitle]="true"
    title="Agregar Ticket"
    [dragEnabled]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="popudEditar"
    (onHidden)="popud_close()"
  >
    <div *dxTemplate="let data of 'content'" class="widget-container">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Seleccione un tema</div>
          <div class="dx-field-value">
            <dx-select-box
              displayExpr="descripcion"
              valueExpr="id"
              [dataSource]="oListadoTiposTicket"
              [(value)]="selTipoTicketId"
              [inputAttr]="{ 'aria-label': 'Product ID' }"
            ></dx-select-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Descripcion</div>
          <div class="dx-field-value">
            <textarea
              [(ngModel)]="comentario"
              #textLength
              [maxlength]="50"
              class="form-control char-textarea"
              id="textarea-counter"
              rows="3"
              placeholder="Comentario"
            ></textarea>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Telefono</div>
          <div class="dx-field-value">
            <input
              [(ngModel)]="numeroTelefono"
              class="form-control"
              placeholder="1234 56 78"
              type="text"
              mask="0000 00 00"
            />
          </div>
        </div>
      </div>
      <br />
      <button
        type="button"
        class="btn btn-primary"
        (click)="guardar()"
        rippleEffect
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Agregar Ticket"
      >
        <i class="far fa-save mx-2"></i>Agregar
      </button>
    </div>
  </dx-popup>
</div>
