import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

//import { AuthenticationService } from 'app/auth/service';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { estadoCuentaItem } from '../../modelos/estadoCuentaItem';
import { listaContratosItem } from '../../modelos/listaContratosItem';
import { listaMensualidadItem } from 'src/app/modelos/listaMensualidadItem';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(private _http: HttpClient, private router: Router) {
    this.currentContratoSubject = new BehaviorSubject<listaContratosItem>(new listaContratosItem()) ;
    this.currentContrato$ = this.currentContratoSubject.asObservable();
  }

  /**
   * Manejo de errores
   * @param error Objeto Eror
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.status === 401) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('contratoDoc');
        localStorage.removeItem('token');
        localStorage.removeItem('contratos');
        localStorage.removeItem('contratoActual');
        this.router.navigateByUrl['/login-form'];
      }
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }


  //public
  public currentContrato$: Observable<listaContratosItem>;

  //private
  private currentContratoSubject: BehaviorSubject<listaContratosItem>;

  private contratos: Array<listaContratosItem> = [];
  private contratosel: string | undefined;
  private realizarPrimeraBusqueda: boolean = true;
  private _estadoCuenta: estadoCuentaItem = new estadoCuentaItem();
  private _listadoMensualidades: Array<listaMensualidadItem> | undefined;

  public get EstadoCuentaData(): estadoCuentaItem {
    return this._estadoCuenta;
  }

  public set ListaContratos(_contratos: Array<any>) {
    const contratos = JSON.parse(localStorage.getItem('contratos'))
    this.contratos = _contratos;
    let ddd: listaContratosItem = _contratos[0];
    this.ContratoSeleccionado = ddd.idContrato;
  }

  public get ListaContratos(): Array<listaContratosItem> {
    return this.contratos;
  }

  public set ContratoSeleccionado(contratoId: string) {
    let _contrato = new listaContratosItem()
    _contrato.idContrato = contratoId;

    this.currentContratoSubject.next(_contrato);

  }

  public get ContratoSeleccionado(): string {
    return this.currentContratoSubject.value.idContrato
  }

  public get RealizarPrimeraBusqueda(): boolean {
    return this.realizarPrimeraBusqueda;
  }

  public set RealizarPrimeraBusqueda(valor: boolean) {
    this.realizarPrimeraBusqueda = valor;
  }

  getEstadoCuenta(idContrato: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );


    return this._http.get<any>(`${environment.apiUrl}/Cuenta/estadoCuenta/${idContrato}`,
      { headers: encabezado })
      .pipe(
        map(vData => {
          console.log(vData);
          if (vData.estado == 1) {
            this._estadoCuenta = vData.datos;
            return true;
          } else {
            return false;
          }
        })
      );
  }

  /**
   *
   * @param idContrato
   * @returns
   */
  getEstadoCuentaDoc(idContrato: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    return this._http.get<any>(`${environment.apiUrl}/Cuenta/estadoCuenta/${idContrato}`,
      { headers: encabezado })
      .pipe(
        map(vData => {
          //console.log(vData);
          return vData;
        })
      );
  }

  getListaMensualidades(idContrato: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );

    console.log(`${environment.apiUrl}/Cuenta/listaMensualidades/${idContrato}`);
    return this._http.get<any>(`${environment.apiUrl}/Cuenta/listaMensualidades/${idContrato}`,
      { headers: encabezado })
      .pipe(retry(1), catchError(this.handleError),
        map(vData => {
          console.log(vData);
          if (vData.estado == 1) {
            this._listadoMensualidades = vData.datos;
            return this._listadoMensualidades;
          } else {
            return null;
          }
        }, error => console.error(error))
      );

  }

  getAnchoBanda(idContrato: string, intervaloM: string, periodoH: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );


    return this._http
      .get<any>(`${environment.apiUrl}/Bequan/consumoAnchoBanda/${idContrato}/${intervaloM}/${periodoH}`,
      { headers: encabezado });

  }


}
