
<div class="container-fluid">
  <div class="row">
    <div class="col-12 ">
      <div class="card mb-1 mt-2">
        <div class="card-header" >
          <h5>Promesas de Pago</h5>
        </div>
        <div class="card-body">
          <form action="" (submit)="onFormSubmit($event)">
            <dx-form
              id="frmFormulario"
              [formData]="formulario"
              labelLocation="top"

              [colCount] = "1">

            <dxi-item
                dataField="tipo"
                editorType="dxSelectBox"
                [editorOptions]="{dataSource: tipoPago, displayExpr: 'nombre', valueExpr: 'id'}">
                <dxo-label text="Como Pagara"></dxo-label>
                <dxi-validation-rule type="required" message="La forma como pagará es Obligatorio">
                </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              dataField="observacion"
              editorType="dxTextArea"
              [editorOptions]="{ disabled: false, height: 90, maxLength: 200 }">
              <dxo-label text="Razon ">
              </dxo-label>
            </dxi-item>

          <dxi-item itemType="button" [buttonOptions]="registerButtonOptions">
          </dxi-item>

        </dx-form>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>


