export class estadoCuentaItem {
    idCliente: string;
    estatus: string;
    enlace: string;
    nombre: string;
    direccion: string;
    saldo: string;
    nTicket: string;
    tipoTicket:string;
    estadoTicket:string;

}