import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { registerGradient } from 'devextreme/common/charts';
import { ShareService } from 'src/app/shared/services/share-service.service';

@Component({
  selector: 'app-bandwidth',
  templateUrl: './bandwidth.component.html',
  styleUrls: ['./bandwidth.component.scss'],
})
export class BandwidthComponent implements OnInit, OnChanges {
  @Input('contrato') contrato: any = {};
  final: IBandWithFinal[] = [];
  bounds: any ={
    startValue: 0,
    endValue: 0
  }
  valueText: string;
  constructor(private shareService: ShareService) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Contrato: ', this.contrato);
    this.getData();
  }

  ngOnInit(): void {
    console.log('Contrato: ', this.contrato);
    this.getData();
  }

  velocidadPersonalizada() {
    return `${this.valueText} mbs`;
  }

  subidaCustomizeText() {
    return `${this.valueText} mbs`;
  }

  getData() {
    console.log('Contrato: ', this.contrato.idCliente, this.contrato.idCliente);
    if (this.contrato.idCliente !== undefined) {
      this.shareService
        .getAnchoBanda(this.contrato.idCliente, '5', '12')
        .subscribe((vData) => {
          //console.log(vData);
          let resp: IBandwith = vData.datos;
          const cantidad: number = vData.datos.dataDownload.length;
          let x: number = 0;
          this.final = [];
          console.log(resp, cantidad);
          for (x = 0; x < cantidad; x++) {
            let xy: IBandWithFinal = {
              upLoad: resp.dataUpload[x],
              downLoad: resp.dataDownload[x],
              hora: resp.timestamp[x],
            };
            this.final.push(xy);
          }
        this.final = this.final.filter(x => x.downLoad > 0);
          console.log(this.final);
        });
      setTimeout(() => {
        this.shareService
          .getAnchoBanda(this.contrato.idCliente, '2', '1')
          .subscribe((vData) => {
            console.log(vData);
            let resp: IBandwith = vData.datos;
            const cantidad: number = vData.datos.dataDownload.length;
            let x: number = 0;
            let final: IBandWithFinal[] = [];
            console.log(resp, cantidad);
            for (x = 0; x < cantidad; x++) {
              let xy: IBandWithFinal = {
                upLoad: resp.dataUpload[x],
                downLoad: resp.dataDownload[x],
                hora: resp.timestamp[x],
              };
              final.push(xy);
            }
            this.final = this.final.filter(x => x.downLoad > 0);
            console.log(final);
          });
      }, 1000 * 60 * 5);
    }
  }

  seriesColor = {
    base: "#f5564a",
    fillId: registerGradient("linear", {
        colors: [{
            offset: "20%",
            color: "#97c95c"
        }, {
            offset: "90%",
            color: "#eb3573"
        }]
    })
  };
}

interface IBandwith {
  timestamp: string[];
  dataDownload: number[];
  dataUpload: number[];
  suscriptor: string;
  estado: number;
  mensaje: string;
}

interface IBandWithFinal {
  upLoad: number;
  downLoad: number;
  hora: string;
}
