import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { listadoTicketsItem } from '../../modelos/listadoTicketsItem';
import { Clretornoregistro } from '../../modelos/respuestaCosultas';
import { ticketTipoItem } from '../../modelos/ticketTipoItem';

import { throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ShareService } from './share-service.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private _http: HttpClient,private _shereService: ShareService) { }


  getListaTickets(contrato: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );

    console.log(`${environment.apiUrl}/Ticket/listadoTickets/${contrato}`)
    return this._http.get<any>(`${environment.apiUrl}/Ticket/listadoTickets/${contrato}`,
      { headers: encabezado })
      .pipe(
        map(vData => {
          console.log('ticket-services ', vData);
          if(vData.estado == 1){
            let _oRetorno:Array<listadoTicketsItem>;
            _oRetorno= vData.datos;
            return _oRetorno;
          }else{
            return null;
          }
        })
      );
  }

  getListadoTipoTicket() {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );

    return this._http.get<any>(`${environment.apiUrl}/Ticket/listadoTicketsTipos/`,
      { headers: encabezado })
      .pipe(
        map(vData => {
          if(vData.estado == 1){
            let _oRetorno:Array<ticketTipoItem> ;
            _oRetorno= vData.datos;
            return _oRetorno;
          }else{
            return null;
          }
        })
      );

  }

  crearTicket(tipoTicket: string, sComentario: string, sNumeroTelefono: string, contrato: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );

    return this._http
      .post<any>(`${(environment as any).apiUrl}/Ticket/crearTickets`,
      JSON.stringify({
        contratoId: contrato,
        tipoTicketId: tipoTicket,
        mensaje : sComentario,
        telefono: sNumeroTelefono
      }),
      { headers: encabezado })
      .pipe(
        switchMap(resultado => {


          if (resultado.estado == 1){
              return "1";

          }else{
            const error = resultado.mensaje;

            return throwError(error);

          }

        })
      );
  }

}
