import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ShareService } from './share-service.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import Swal from 'sweetalert2';

export interface IUser {
  id?: number;
  email?: string;
  avatarUrl?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  role?: Role;
  token?: string;
  estado?: number;
  datos?: any;
}

export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User'
}

const defaultPath = '/';
const defaultUser = {
  email: 'javilez173@gmail.com',
  avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
};

@Injectable()
export class AuthService {
  private _user: IUser | null = defaultUser;

  /**
   * @description Retorna verdadero o falso para indicar que el usuario esta logueado
   */
  get loggedIn(): boolean {

    if (localStorage.getItem('currentUser') && localStorage.getItem('token') && localStorage.getItem('fechaVenceToken')) {
      const fechaVenceToken = new Date(localStorage.getItem('fechaVenceToken'));
      const fechaActual = new Date();
      //console.log(fechaVenceToken, fechaActual);
      if (fechaVenceToken > fechaActual) {
        return true;
      } else {
        this.logOut();
        return false;
      }
    }
    return false;
  }

  get getToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token') || undefined;
    }
    return undefined;
  }

  /**
   * Retorna el id del contrato que esta seleccionado por defecto
   */
  get clienteid() {
    if (localStorage.getItem('contrato')) {
      const storage = JSON.parse(localStorage.getItem('contrato'));
      return storage.idCliente || '0';
    }
    return '0';
  }


  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  public currentUser: Observable<IUser>;

  //private
  private currentUserSubject: BehaviorSubject<IUser>;


  constructor(private router: Router,
    private _http: HttpClient,
    private IspSolshareservice: ShareService,

  ) {
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  async logIn(email: string, password: string) {
    const encabezado = new HttpHeaders().set(
      "Content-Type",
      "application/json")
    /*.set(
      'XZYApiKey', '6d18ce56-090a-498e-8134-e515e8f63675'
    );*/


    try {
      const user = await this._http
        .post<any>(`${environment.apiUrl}/Acceso/LoginB`,
          JSON.stringify({
            User: email,
            Password: password,
            Modelo: "xxx",
            Fabricante: "xxx",
            NombreDispositivo: "xxx",
            VersionSDK: "xxx",
            DeviceID: "xxx"
          }),
          { headers: encabezado })
        .toPromise();

      // login successful if there's a jwt token in the response
      //console.log(user);
      if (user.estado == 1) {
        if (user && user.datos.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //console.log('Vamos a intentar llenar el objeto de respuesta');
          localStorage.setItem('token', user.datos.accessToken);
          localStorage.setItem('fechaVenceToken', this.getFechaVenceToken(environment.minutosValidoToken));

          let auser: any = {
            email: '',
            firstName: '',
            id: 0,
            lastName: '',
            token: '',
            avatar: '',
            password: ''
          };
          auser.email = "javilez173@gmail.com";
          auser.firstName = "jose";
          auser.id = 1;
          auser.lastName = "";
          auser.token = user.datos.accessToken;
          auser.avatar = "fff.jpg";
          auser.password = "";
          localStorage.setItem('currentUser', JSON.stringify(auser));
          this.IspSolshareservice.ListaContratos = user.datos.data;

          for (const item of user.datos.data) {
            // vamos a mandar a llamar la parte de la informacion del contrato para saber los valores
            const x = await this.IspSolshareservice.getEstadoCuentaDoc(item.idContrato).toPromise();
            //console.log(item, x);
            const { datos, estado } = x;
            //console.log(datos)
            if (localStorage.getItem('contratoDoc')) {
              let dd: any[] = JSON.parse(localStorage.getItem('contratoDoc'));

              dd.push(datos)
              localStorage.setItem('contrato', JSON.stringify(dd[0]));
              localStorage.setItem('contratoDoc', JSON.stringify(dd));
            } else {
              let dd: any[] = [];
              dd.push(datos)
              localStorage.setItem('contratoDoc', JSON.stringify(dd));
            }
          }

          localStorage.setItem('contratos', JSON.stringify(user.datos.data));
          //await this.sleep(3000);
          //setTimeout(()=> console.log('Validando informacion'), 10000);
          this.currentUserSubject.next(auser);
        }
      }
      //console.log(user)
      return {
        isOk: true,
        data: user
      };


    }
    catch {
      const obj: any = {
        isOk: false,
        message: "Authentication failed"
      };
      return obj;
    }
  }

  async sleep(fn, ...args) {
    await timeout(3000);
    return fn(...args);
  }
  async getUser() {
    try {
      // Send request
      if (localStorage.getItem('currentUser')) {
        let auser: any = {
          email: '',
          firstName: '',
          id: 0,
          lastName: '',
          token: '',
          avatar: '',
          password: ''
        };
        const storage = JSON.parse(localStorage.getItem('currentUser'));

        auser.email = "javilez173@gmail.com";
        auser.firstName = "jose";
        auser.id = 1;
        auser.lastName = "";
        auser.token = storage.accessToken;
        auser.avatar = "fff.jpg";
        auser.password = "";

        return {
          isOk: true,
          data: auser
        };
      }
      return {
        isOk: false,
        data: null
      }
    }
    catch {
      return {
        isOk: false,
        data: null
      };
    }
  }

  async createAccount(email: string, password: string) {
    try {
      // Send request

      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  }

  async resetPassword(email: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('contratos');
    localStorage.removeItem('contratoDoc');
    localStorage.removeItem('contrato');
    this.router.navigate(['/login-form']);
    location.reload();

  }

  getFechaVenceToken(minutes: number): string {
    const date = new Date(); // Obtén la fecha y hora actual
    date.setMinutes(date.getMinutes() + minutes); // Suma 60 minutos

    // Formatea la fecha en el formato deseado
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutesStr = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutesStr}`;
    return formattedDate;
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
