


export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'http://209.126.4.189:46000/apiClienService',
  minutosValidoToken: 60,
  permitirCuotaParcial: false,
  guardaLog: true,
  apiLogTransacciones: 'http://logs.arteagasoft.com/api/v1/logtransacciones?code=',
  // apiUrl: 'http://localhost:4000'
};
