import { AfterContentInit, AfterViewInit, Component, OnChanges } from '@angular/core';
//import { CardAnalyticsComponent } from 'src/app/pages/library/card-analytics/card-analytics.component'


@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ]
})

export class HomeComponent implements AfterViewInit, AfterContentInit, OnChanges {

  isLoading: boolean = true;
  public contratos: any[] = [];
  nombre: string = ''
  contratoInfo: any = {};
  contratoActual: any = 0;


  constructor() {
    this.contratos = JSON.parse(localStorage.getItem('contratoDoc'));
  }
  ngAfterContentInit(): void {
    this.contratos = JSON.parse(localStorage.getItem('contratoDoc'));
  }
  ngAfterViewInit(): void {
    this.contratos = JSON.parse(localStorage.getItem('contratoDoc'));
    if(localStorage.getItem('contrato')) {
      this.contratoActual = JSON.parse(localStorage.getItem('contrato')).idCliente;
    }
  }

  ngOnChanges(): void {
    this.contratos = JSON.parse(localStorage.getItem('contratoDoc'));
  }

  cambioContrato(contrato: any) {
    const xy = this.contratos.filter(x => x.idCliente === contrato.value);
    if(xy.length > 0) {
      this.nombre = xy[0].nombre;
      this.contratoInfo = xy[0];
      localStorage.setItem('contrato', JSON.stringify(xy[0]));
    } else {
      this.nombre = 'N/A'
    }
  }
}
//
