import { CommonServices } from './../../shared/services/common.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import notify from 'devextreme/ui/notify';
import { AuthService } from 'src/app/shared/services/auth.service';
//import { CommonServices } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-promesa-pago',
  templateUrl: './promesa-pago.component.html',
  styleUrls: ['./promesa-pago.component.scss']
})
export class PromesaPagoComponent implements OnInit, OnDestroy {
  suscriptionsList: any[] = [];
  formulario: any = {
    idCliente: '',
    Token: '',
    Origen: 'WEBAPP',
    tipo: 0,
    observacion: ''
  };

  tipoPago:any  = [{
    id: "1",
    nombre: 'Pagara Mañana'
  }, {
    id: "3",
    nombre: 'Pagara en 3 días'
  }, {
    id: "2",
    nombre: 'Deposito a Banco'
  }]

  colCountByScreen = {
    md: 2,
    sm: 2,
  };

  screen = (width: number) => (width < 720 ? 'sm' : 'md');

  registerButtonOptions: DxButtonTypes.Properties = {
    text: 'Confirmar',
    type: 'default',
    width: '120px',
    useSubmitBehavior: true,
  };

  constructor(private auth: AuthService, private cmService: CommonServices) {

    this.formulario = {
      idCliente: auth.clienteid,
      Token: auth.getToken,
      tipo: "0",
      observacion: ''
    };
  }

  ngOnDestroy(): void {
    this.suscriptionsList.forEach(suscription => {
      suscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.formulario = {
      idCliente: this.auth.clienteid,
      Token: this.auth.getToken,
      tipo: 0,
      observacion: ''
    };

  }

  onFormSubmit = (e: SubmitEvent) => {
    console.log(e, this.formulario);
    const sv = this.cmService.addElemento(this.formulario, 'Cuenta/crearPromesaDePago')
      .subscribe(v => {
        console.log(v);
        if(v.mensaje){
          if(v.mensaje === 'ok') {
            notify('Promesa de pago registrada correctamente', 'success', 5000);
          } else {
            notify('Error al registrar la promesa de pago', 'error', 5000);
          }
        } else {
          notify('Error al registrar la promesa de pago', 'error', 5000);
        }
        this.formulario.tipo = "0";
        this.formulario.observacion = "";

      });
    this.suscriptionsList.push(sv);

  }

}
