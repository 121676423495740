import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { listadoTicketsItem } from '../../modelos/listadoTicketsItem';
import { ticketTipoItem } from '../../modelos/ticketTipoItem';
import { TicketService } from 'src/app/shared/services/ticket-services.service';
import { NotificacionService } from 'src/app/shared/services/notificacion-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  public contentHeader: object | undefined;
  public rows: Array<listadoTicketsItem> = [];
  public oListadoTiposTicket: Array<ticketTipoItem> | undefined
  public selTipoTicketText: string = '';
  selTipoTicketId: string = '';
  public comentario: string = '';
  public numeroTelefono: string = '';
  public loading = false;
  popudEditar: boolean = false;
  public contratos: any[] = [];
  contrato:string = '';
  contratoActual: any = 0;

  constructor(private ticketService: TicketService,
    private servicioNotificaciones: NotificacionService) {
  }

  ngOnInit(): void {

    this.contratos = JSON.parse(localStorage.getItem('contratoDoc'));
    if(localStorage.getItem('contrato')) {
      this.contratoActual = JSON.parse(localStorage.getItem('contrato')).idCliente;
      this.contrato = this.contratoActual
      this.obtenerListaDeTickets(this.contrato);
    }
  }

  cambioContrato(evento: any) {
    this.contrato = evento.value;
    console.log(evento);
    this.obtenerListaDeTickets(this.contrato);
  }

  obtenerListaDeTickets(contrato: string) {
    this.loading = true;
    this.ticketService.getListaTickets(contrato)
      .subscribe(x => {
      if (x) {
        this.rows = x;
        this.loading = false;
      }
    },
      (error) => {
        console.log(
          "There was an error while retrieving Device Count!" + error
        );
        this.loading = false;
      });
  }

  popud_close() {
    this.popudEditar = false;
  }

  guardar() {
    this.loading = true;
    this.popudEditar = false;
    console.log(this.selTipoTicketId, this.comentario, this.numeroTelefono)
    this.ticketService
      .crearTicket(this.selTipoTicketId, this.comentario, this.numeroTelefono, this.contrato)
      .subscribe(p => {
        this.limpiarVariables();
        Swal.fire({
          title: 'Guardado...',
          text: 'Ticket guardado', icon: 'info'
        });
        this.obtenerListaDeTickets(this.contrato);
      }, error => {
        this.limpiarVariables();
        console.log("Se presento el siguiente error al grabar el ticket " + error);
        this.servicioNotificaciones.toastrError("😞 Se presento el siguiente error al grabar el ticket " + error);
        this.loading = false;
      });
  }

  // modal Open Default
  modalOpenDefault() {
    this.popudEditar = true;
    this.ticketService.getListadoTipoTicket().subscribe(x => {
      if (x) {
        this.oListadoTiposTicket = x;
        this.selTipoTicketText = x[0].descripcion;
        this.loading = false;
      }
    });


  }

  limpiarVariables() {
    this.selTipoTicketId = '';
    this.comentario = '';
    this.numeroTelefono = '';
  }

  cambiarTipo(valorId: string, valorTitulo: string): void {
    this.selTipoTicketId = valorId;
    this.selTipoTicketText = valorTitulo;
  }


}
