<div class="demo-device">

  <div class="row">

    <div class="col-md-9 col-sm-12">

      <div class="row">

        <div class="col-md-6 col-sm-12">
          <div class="card custom-card">
            <div class="card-header">
              <h6>
                SELECCIONE UN CONTRATO
              </h6>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between p-md-1">
                <div class="d-flex flex-row">

                  <div class="align-self-center mx-2">
                    <dx-select-box
                      id="contrato"
                      displayExpr="idCliente"
                      valueExpr="idCliente"
                      width="140"
                      [dataSource]="contratos"
                      (onValueChanged)="cambioContrato($event)"
                      [value]="contratoActual"
                      ></dx-select-box>
                  </div>

                  <div class=" align-content-center mx-1">
                    <h5 class="">{{ nombre }}</h5>
                    <!--
                    <p *ngIf="contratoInfo.estatus === 'CONECTADO'" class="mb-1 bg-success-subtle align-content-between"><strong> {{contratoInfo.estatus}} </strong></p>
                    <p *ngIf="contratoInfo.estatus === 'RETIRADO'" class="mb-1 bg-danger-subtle align-content-between"><strong> {{contratoInfo.estatus}} </strong></p>
                    -->
                  </div>

                  <div class=" align-content-end position-absolute end-0 mx-2">
                    <i *ngIf="contratoInfo.estatus === 'CONECTADO'" class="fas fa-user-check text-success fa-3x"></i>
                    <i *ngIf="contratoInfo.estatus === 'RETIRADO'" class="fas fa-user-slash text-danger fa-3x"></i>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="card custom-card">
            <div class="card-header">
              <h6>
                SALDO
              </h6>
            </div>
            <div class="card-body">

              <section class="row">
                <main class="col" style="width: 50%;">
                  <div class="d-flex justify-content-between p-md-1">
                    <div class="align-self-center align-content-start">
                      <i *ngIf="contratoInfo.saldo === undefined" class="fa-solid fa-0 text-danger fa-3x"></i>
                      <i *ngIf="contratoInfo.saldo === 0" class="fa-regular fa-thumbs-up text-success fa-3x"></i>
                      <i *ngIf="contratoInfo.saldo > 0" class="fas fa-dollar-sign text-danger fa-3x"></i>
                    </div>
                    <!-- aQUI ESTA LA SECCION DONDE MUESTRO EL BILLETE-->
                    <div class="d-flex flex-row">
                      <div class=" align-self-start ">
                        <!-- <blockquote class="blockquote"> -->
                        <h2>
                          {{ contratoInfo.saldo | number : ".2" }}
                        </h2>
                      </div>

                    </div>

                  </div>
                </main>
                <aside class="col" style="width: 50%;">
                  <p class=" align-content-end ">
                    <button type="button" class=" btn btn-success mb-1 float-end" style="width: 70%;">
                      <i class="fas fa-file-invoice-dollar text-info-emphasis fa-2x mx-1"></i>
                      <strong >Realizar Pago</strong>
                    </button>
                    <button type="button" class="btn btn-info float-end" style="width: 70%;">
                      <i class="fas fa-handshake text-danger fa-2x mx-1"></i>

                      <strong >Promesa de Pago</strong>
                    </button>
                </p>
                </aside>
              </section>
            </div>

          </div>
        </div>

        <!-- <div class="col-md-3">
          <div class="card">
            <div class="card-header">
              <h6>PROMESAS DE PAGO</h6>
            </div>

            <div class="card-body ">
              <p>
                <button type="button" class="btn btn-info btn-lg " style="width: 100%;">
                  <i class="fas fa-file-invoice-dollar text-danger fa-2x mx-1"></i>
                  <strong >Promesa de Pago</strong>
                </button>
            </p>
            </div>
          </div>
        </div> -->

      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card custom-card">
            <div class="card-header border-0">
              <h6>Info. General</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Dirección:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext">
                    {{ contratoInfo.direccion }}
                  </div>
                </div>
              </div>

              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Tipo:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext">
                    {{ contratoInfo.enlace }}
                  </div>
                </div>
              </div>

              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Estado:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext ">
                    <p class="">
                      <span *ngIf="contratoInfo.estatus === 'CONECTADO'" class=" color-fondo-success"><strong>{{
                          contratoInfo.estatus }}</strong></span>
                      <span *ngIf="contratoInfo.estatus === 'RETIRADO'" class=" color-fondo-danger"><strong>{{
                          contratoInfo.estatus }}</strong></span>
                    </p>
                  </div>
                </div>
              </div>



              <!--<div class="row">
            <div class="bg-secondary">
              <div class="col d-flex justify-content-between">
                <p class="display-4 align-self-end"> <strong>Saldo:</strong> {{ contratoInfo.saldo }} <small><em>LPS</em></small></p>


              </div>
            </div>
          </div>
        -->
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card custom-card">
            <div class="card-header border-0">
              <h6>Ultimo Ticket</h6>
            </div>

            <div class="card-body">
              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Ultimo Ticket:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext">
                    {{ contratoInfo.nTicket }}
                  </div>
                </div>
              </div>

              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Tipo Ticket:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext">
                    {{ contratoInfo.tipoTicket }}
                  </div>
                </div>
              </div>

              <div class="row">
                <label for="staticEmail" class="col-sm-2 col-form-label">Estado Ticket:</label>
                <div class="col-sm-10">
                  <div class="form-control-plaintext">
                    {{ contratoInfo.estadoTicket }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header border-0">
              <h6>Ancho de Banda</h6>
            </div>
            <div class="card-body">
              <app-bandwidth [contrato]="contratoInfo"></app-bandwidth>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class=" d-none d-md-block col-md-3 col-xs-3">
      <img src="../../../assets/images/banner/banner.jpg" alt="">
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header border-0">
          <h6>Mensualidades</h6>
        </div>
        <div class="card-body">
          <app-estado [contrato]="contratoInfo"></app-estado>
        </div>
      </div>

    </div>
  </div>


</div>
