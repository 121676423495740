import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';


//import { AuthService } from '../../auth/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

//import { BYPASS_TOKEN } from './auth.interceptor';



@Injectable({
  providedIn: 'root'
})
export class CommonServices {


  //config: any = environment;
  //definimos la variable que se va a utilizar para obtener los valores de entorno
  config: any = environment;

  /**
   * Constructor del servicio
   * @param auth
   * @param __http__
   */
  constructor(private auth: AuthService, private __http__: HttpClient) {

    this.config = environment
  }

  /**
   * Genera los objetos de cabecera necesarios para realizar operaciones con el API
   */
  cabeceras(): HttpHeaders {
    let header =
      new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${this.auth.getToken}` });
    return header;
  }

  /**
   * Manejo de errores
   * @param error Objeto Eror
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  /**
   * Estos son los metodos necesarios para el CRU necesarios
   * @param path Ruta en el Api para obtener los datos
   */
  getLista(path: string): Observable<any> {
    return this.__http__.get(`${this.config.apiUrl}/${path}`)
      .pipe(
        map((res: any) => {
          //console.log('Servicio ', res);
          return res;
        })
      );
  }



  /**
  * Permite consultar una ruta que retorna un objeto especifico
  * @param id Parametro a consultar
  * @param path Ruta del Api, la ruta debe de especificarse completa
  */
  getElemento(id: string, path: string) {
    return this.__http__.get(`${this.config.apiUrl}/${path}/${id}`)
      .pipe(retry(1), catchError(this.handleError),
        map(res => {
          // console.log('Servicio ', res);
          return res;
        })
      );
  }

  /*
      objeto que se va a pasar ocn estas propiedades
      objeto: any = {
        endPoint,
        cabeceraNombre,
        cabeceraValor,
        metodo,
        objecto
      }
  */
  callApiExternaPost(endPoint: string, cabeceraNombre: string, cabeceraValor: any, metodo: string, objecto: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      [cabeceraNombre]: cabeceraValor
    });
    //console.log('Cabecera: ', headers, cabeceraValor);
    //console.log('Valores: ', objecto);
    //console.log('ApiObj: ', `${endPoint}/${metodo}`)
    return this.__http__.post(`${endPoint}/${metodo}`, objecto, {headers})
      .pipe(retry(1), catchError(this.handleError),
        map(res => {
          //console.log('Servicio ', res);
          return res;
        })
      );
  }

  /**
   * Permite hacer una peticion HTTPGET, a una API, generando los endpoint y cabeceras de forma dinamica
   * @param endPoint EndPoint al que se apuntara
   * @param cabeceraNombre nombre de la cabecera, a la que se le enviara los datos
   * @param cabeceraValor Valor de la cabecera
   * @param metodo Metodo ojo no es el metodo HTTP, sino el nombre del metodo que se consulta ejemplo /logout
   * @returns retorna un objeto, el cual puede ser una lista o un objeto simple, al ser un metodo dinamico, se desconoce el retorno, vea la documentacion de su api a consultar
   */

  /*
  callApiExternaGet(endPoint: string, cabeceraNombre: string, cabeceraValor: any, metodo: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      [cabeceraNombre]: cabeceraValor
    });

    return this.__http__.get(`${endPoint}/${metodo}`, {headers, context: new HttpContext().set(BYPASS_TOKEN, true)})
      .pipe(retry(1), catchError(this.handleError),
        map(res => {
          //console.log('Servicio ', res);
          return res;
        })
      );
  }

  */

    /**
   * Permite hacer una peticion HTTPGET, a una API, generando los endpoint y cabeceras de forma dinamica
   * @param endPoint EndPoint al que se apuntara
   * @param cabeceraNombre nombre de la cabecera, a la que se le enviara los datos
   * @param cabeceraValor Valor de la cabecera
   * @param metodo Metodo ojo no es el metodo HTTP, sino el nombre del metodo que se consulta ejemplo /logout
   * @returns retorna un objeto, el cual puede ser una lista o un objeto simple, al ser un metodo dinamico, se desconoce el retorno, vea la documentacion de su api a consultar
   */
  /*

    callApiExternaGetBinary(endPoint: string, cabeceraNombre: string, cabeceraValor: any, metodo: string) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        [cabeceraNombre]: cabeceraValor
      });

      return this.__http__.get(`${endPoint}/${metodo}`,
        {
          headers, context: new HttpContext().set(BYPASS_TOKEN, true),
          observe: 'response', responseType: 'blob'
        })
        .pipe(retry(1), catchError(this.handleError),
          map((response: HttpResponse<Blob>) => {
            //console.log('Servicio ', res);
            const contentType = response.headers.get('Content-Type');

            // Lógica para determinar el tipo de contenido y actuar en consecuencia
            if (contentType?.startsWith('image/')) {
              // Manejar imagen
              console.log('Es una imagen');
            } else if (contentType?.startsWith('video/')) {
              // Manejar video
              console.log('Es un video');
            } else if (contentType === 'application/pdf') {
              // Manejar PDF
              console.log('Es un documento PDF');
            } else if (contentType?.startsWith('application/vnd.openxmlformats-officedocument') ||
                      contentType?.startsWith('application/msword')) {
              // Manejar documentos de Office
              console.log('Es un documento de Office');
            } else {
              // Otros tipos MIME
              console.log('Tipo MIME no reconocido');
            }

            // Devolver la respuesta completa si es necesario
            return {contentType: contentType, body: response.body};
          })
        );
    }

    */

  /**
  * Permite agregar un objeto mediante el metodo Post
  * @param _app Elemento a agregar
  * @param path Ruta del API
  */
  addElemento(_app: any, path: string): Observable<any> {
    console.log(path, `${this.config.apiUrl}/${path}`);
    return this.__http__.post(`${this.config.apiUrl}/${path}`,
      _app)
      .pipe(retry(1), catchError(this.handleError),
        map((resp: any) => {
          return resp;
        })
      );
  }

  /**
   * Permite gestionar un objeto mediante el metodo PUT
   * @param _app Objeto a modificar
   * @param id ID del objeto
   * @param path Ruta del API
   */
  editElemento(_app: any, id: any, path: string): Observable<any> {
    // console.log('servicio_editando ', _app, `${config.apiUrl}/${path}/${id}`, id);

    return this.__http__.patch(`${this.config.apiUrl}/${path}/${id}`, _app
    ).pipe(retry(1), catchError(this.handleError),
      map((map) => {
        return map;
      })
    );

  }

  /**
   * Elimina un objeto
   * @param id ID del elemento a eliminar
   * @param path Ruta del API
   */
  deleteElemento(id: string, path: string): Observable<any> {
    return this.__http__.delete(`${this.config.apiUrl}/${path}/${id}?lap=${Date.now()}`)
      .pipe(retry(1), catchError(this.handleError),
        map(r => {
          return r;
        })
      );
  }

  getExcelFile(path: string): Observable<any> {
    return  this.__http__.get(`${this.config.apiUrl}/${path}?lap=${Date.now()}`, { responseType: 'blob' })
      .pipe(retry(1), catchError(this.handleError),
        map( r => {
          return r;
        })
      )
  }

}
