
<dx-chart
  id="chart"
  [dataSource]="final">

  <dxo-argument-axis argumentType="datetime"> </dxo-argument-axis>

  <dxi-value-axis > </dxi-value-axis>
  <dxo-title text="Ancho de Banda" subtitle="AAPL"></dxo-title>
  <dxi-series
    valueField="downLoad"
    color="#82E0AA"
    type="stackedarea"
    name="Descarga" >
  </dxi-series>

  <dxi-series
    valueField="upLoad"
    name="Subida"
    [color]="seriesColor"></dxi-series>

    <dxi-value-axis name="temperature">
      <dxo-title text="valocidad en mbs">
        <dxo-font color="#e91e63"></dxo-font>
      </dxo-title>
      <dxo-label>
        <dxo-font color="#e91e63"></dxo-font>
      </dxo-label>
    </dxi-value-axis>

  <dxo-common-series-settings argumentField="hora"  >
    <dxo-point [visible]="false"></dxo-point>
  </dxo-common-series-settings>

  <dxo-common-axis-settings [valueMarginsEnabled]="false">
  </dxo-common-axis-settings>
  <dxo-argument-axis type="discrete"></dxo-argument-axis>

  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" hoverMode="excludePoints">
  </dxo-legend>
  <dxo-tooltip [enabled]="true"></dxo-tooltip>
  <dxo-export [enabled]="true"></dxo-export>
</dx-chart>


<!--

<dx-chart
  id="chart"
  [dataSource]="final"
  [stickyHovering]="true"
  title="Ancho de Banda"
  defaultPane="bottomPane"
>
  <dxi-series
    pane="topPane"
    valueField="downLoad"
    color="#82E0AA"
    name="Descarga" >
    <dxo-label [visible]="false" [customizeText]="velocidadPersonalizada">
    </dxo-label>
  </dxi-series>

  <dxi-series
    valueField="upLoad"
    name="Subida"
    color="#AF7AC5"
    >
    <dxo-label [visible]="false" [customizeText]="subidaCustomizeText">
    </dxo-label>
  </dxi-series>

  <dxi-value-axis name="mbs" [allowDecimals]="false">
    <dxo-title text="Descargas, mb/s">
      <dxo-font ></dxo-font>
    </dxo-title>
    <dxo-label>
      <dxo-font color="#ff950c"></dxo-font>
    </dxo-label>
  </dxi-value-axis>

  <dxi-value-axis pane="bottomPane">
    <dxo-grid [visible]="true"></dxo-grid>
    <dxo-title text="Subida, mbs"></dxo-title>
  </dxi-value-axis>

  <dxo-common-series-settings argumentField="hora"
    type="splinearea" hoverMode="includePoints">
    <dxo-point hoverMode="allArgumentPoints"></dxo-point>
  </dxo-common-series-settings>

  <dxi-pane name="topPane"></dxi-pane>
  <dxi-pane name="bottomPane"></dxi-pane>

  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" hoverMode="excludePoints">
  </dxo-legend>
  <dxo-tooltip [enabled]="true"></dxo-tooltip>
  <dxo-export [enabled]="true"></dxo-export>
</dx-chart>
 -->
