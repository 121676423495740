import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor() { }

  toastrError(msg) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: '<a href="#">Why do I have this issue?</a>'
    });
  }

  toastrSuccess() {
    Swal.fire({
      title: "Success",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    });

  }
}
