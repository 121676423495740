import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {ShareService} from '../../../shared/services/share-service.service'
@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.scss']
})
export class EstadoComponent implements OnChanges, OnInit {
  isLoading: boolean = false;
  @Input('contrato')contrato: any = {};
  rows: any[] = []

  ngOnChanges(changes: SimpleChanges): void {
      console.log(this.contrato);
      this.getdata()
  }

  ngOnInit(): void {
      this.getdata();
  }

  constructor(private shareService: ShareService) { }
  getdata(){
    const contrato = JSON.parse(localStorage.getItem('contrato'));
    const data = this.shareService.getListaMensualidades(contrato.idCliente)
      .subscribe(v => {
        this.rows = v;
      });

  }
}
