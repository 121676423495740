<div class="demo-device">
  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <dx-data-grid
          #grid
          id="gridContainer"
          [repaintChangesOnly]="true"
          [dataSource]="rows"
          [focusedRowEnabled]="true"
          keyExpr="nFactura"
          [hoverStateEnabled]="true"
          [wordWrapEnabled]="true"

          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [focusedRowEnabled]="true"
          [columnHidingEnabled]="true"
        >
          <dxo-search-panel
            [visible]="true"
            [width]="240"
            placeholder="Buscar..."
          ></dxo-search-panel>
          <dxo-pager
            [allowedPageSizes]="[5, 8, 15, 30]"
            [showInfo]="true"
            [showNavigationButtons]="true"
            [showPageSizeSelector]="true"
            [visible]="true"
          >
          </dxo-pager>

          <dxo-paging [pageSize]="8"></dxo-paging>
          <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

          <dxo-export
            [enabled]="true"
            fileName="listado_de_mensualidades"
            [allowExportSelectedData]="true"
          ></dxo-export>
          <dxo-paging [pageSize]="15" [enabled]="true"></dxo-paging>
          <dxi-column dataField="fEmitida" caption="Fecha" dataType="date"></dxi-column>
          <dxi-column dataField="fechaPro" caption="Periodo"></dxi-column>
          <dxi-column dataField="moneda" caption="Moneda"></dxi-column>
          <dxi-column dataField="valor" caption="Valor"></dxi-column>
          <dxi-column dataField="estatus" cellTemplate="cellTemplate" caption="Estado"></dxi-column>
          <div *dxTemplate="let data of 'cellTemplate'">
            <p *ngIf="data.data.estatus==='CL'" class="text-success "><span class="badge color-fondo-success"><em>Pagado</em></span></p>
            <p *ngIf="data.data.estatus==='OP'" class=" text-danger "><span class="badge color-fondo-danger"><em>Pendiente</em></span></p>
          </div>
        </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
